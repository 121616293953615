@font-face {
    font-family: "Digital Desolation";
	src: url("/fonts-scorm/Digital-Desolation.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Math Two Regular";
	src: url("/fonts-scorm/Euclid-Math-Two.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Math Two";
	src: url("/fonts-scorm/Euclid-Math-Two.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol Regular";
	src: url("/fonts-scorm/eucsym_01.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol";
	src: url("/fonts-scorm/eucsym_01.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol Bold";
    font-weight: bold;
	src: url("/fonts-scorm/eucsymb.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol";
    font-weight: bold;
	src: url("/fonts-scorm/eucsymb.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol Italic";
    font-style: italic;
	src: url("/fonts-scorm/eucsymi_01.ttf") format("truetype")
}

@font-face {
    font-family: "Euclid Symbol";
    font-style: italic;
	src: url("/fonts-scorm/eucsymi_01.ttf") format("truetype")
}

@font-face {
    font-family: "Euro Mono Std";
	src: url("/fonts-scorm/EuroMonoStd-Regular.otf") format("opentype")
}

@font-face {
    font-family: "Euro Mono Std";
    font-weight: bold;
    src: url("/fonts-scorm/EuroMonoStd-Bold.otf") format("opentype")
}

@font-face {
    font-family: "European Pi Std 3";
	src: url("/fonts-scorm/EuropeanPiStd-3.ttf") format("opentype")
}

@font-face {
    font-family: "Mathematical Pi LT Std";
	src: url("/fonts-scorm/MATHEMATICALPILTSTD-1.otf") format("opentype")
}

@font-face {
    font-family: "Mathematical Pi LT Std 1";
	src: url("/fonts-scorm/MATHEMATICALPILTSTD-1.otf") format("opentype")
}

span[style*='font-family: "Mathematical Pi LT Std"'][style*="font-style: normal"][style*="font-weight: normal"] {
    font-family: "Mathematical Pi LT Std Regular" !important;
}


@font-face {
    font-family: "Mathematical Pi LT Std Regular";
	src: url("/fonts-scorm/MathematicalPiLTStd.otf") format("opentype")
}


@font-face {
    font-family: "Mathematical Pi LT Std 3";
	src: url("/fonts-scorm/MATHEMATICALPILTSTD-3.otf") format("opentype")
}

@font-face {
    font-family: "Mathematical Pi LT Std 4";
	src: url("/fonts-scorm/MATHEMATICALPILTSTD-4.OTF") format("opentype")
}

@font-face {
    font-family: "Mathematical Pi LT Std 6";
	src: url("/fonts-scorm/MATHEMATICALPILTSTD-6.OTF") format("opentype")
}

@font-face {
    font-family: "MMa Binary";
	src: url("/fonts-scorm/MMaBinary-Regular.otf") format("opentype")
}

@font-face {
    font-family: "Symbol Tyger";
	src: url("/fonts-scorm/Symbol-Tiger.ttf") format("truetype")
}

@font-face {
    font-family: "Symbol Tiger";
	src: url("/fonts-scorm/Symbol-Tiger.ttf") format("truetype")
}

@font-face {
    font-family: Symbol;
    src: url("/fonts-scorm/Symbol-mcgraw.ttf") format("truetype")
}

@font-face {
    font-family: "Symbol Std";
    src: url("/fonts-scorm/SymbolStd.otf") format("opentype")
}

@font-face {
    font-family: "Zapf Dingbats";
    src: url("/fonts-scorm/ZapfDingbats.ttf") format("truetype")
}

@font-face {
    font-family: "MMCenturyOldGreek";
    font-weight: normal;
    font-style: normal;
	src: url("/fonts-scorm/EuroMonoStd-Regular.otf") format("opentype")
}

@font-face {
    font-family: "MMCenturyOldGreek Bold";
    font-weight: bold;
    font-style: normal;
    src: url("/fonts-scorm/MMCenturyOldGreek-Bold.otf") format("opentype")
}

@font-face {
    font-family: "MMCenturyOldGreek";
    font-weight: bold;
    font-style: normal;
    src: url("/fonts-scorm/MMCenturyOldGreek-Bold.otf") format("opentype")
}

@font-face {
    font-family: "MMCenturyOldGreek Italic";
    font-weight: normal;
    font-style: italic;
	src: url("/fonts-scorm/MMCenturyOldGreek-Italic.otf") format("opentype")
}
@font-face {
    font-family: "MMCenturyOldGreek";
    font-weight: normal;
    font-style: italic;
	src: url("/fonts-scorm/MMCenturyOldGreek-Italic.otf") format("opentype")
}

@font-face {
    font-family: "MMCenturyOldGreek Bold Italic";
    font-weight: bold;
    font-style: italic;
    src: url("/fonts-scorm/MMCenturyOldGreek-BoldItalic.otf") format("opentype")
}

@font-face {
    font-family: "MMCenturyOldGreek";
    font-weight: bold;
    font-style: italic;
    src: url("/fonts-scorm/MMCenturyOldGreek-BoldItalic.otf") format("opentype")
}

[class*="SYMBOL\\ STD"], .SIGMA_cos_14, .SYMBOL_REGULAR, .symbol {
    font-family: 'Symbol Std';
}

[class*="mathematical\\ 1"], [class*="superindice+mathematical"], .mathematical {
    font-family: 'Mathematical Pi LT Std 1';
}


@font-face {
    font-family: "AGaramond";
	src: url("/fonts-scorm/AGaramond-Regular.otf") format("opentype")
}

@font-face {
    font-family: "Calibri";
	src: url("/fonts-scorm/Calibri.ttf") format("truetype")
}

@font-face {
    font-family: "Betaphid";
	src: url("/fonts-scorm/Betaphid-Regular.otf") format("opentype")
}

@font-face {
    font-family: "Futura Std Book";
	src: url("/fonts-scorm/FuturaStd-Book.otf") format("opentype")
}

@font-face {
    font-family: "HelveticaNeueLT Std";
	src: url("/fonts-scorm/HelveticaNeueLTStd-Cn.otf") format("opentype")
}

@font-face {
    font-family: "HelveticaNeueLT Std";
    font-style: italic;
	src: url("/fonts-scorm/HelveticaNeueLTStd-It.otf") format("opentype")
}

@font-face {
    font-family: "MathematicalPi 4";
	src: url("/fonts-scorm/MathematicalPi-Four.otf") format("opentype")
}

@font-face {
    font-family: "MMa Arrow";
	src: url("/fonts-scorm/MMaArrow-Regular.otf") format("opentype")
}

@font-face {
    font-family: "MMa Etc";
	src: url("/fonts-scorm/MMaEtc-Regular.otf") format("opentype")
}

@font-face {
    font-family: "MMa Relation";
	src: url("/fonts-scorm/MMaRelation-Regular.otf") format("opentype")
}

@font-face {
    font-family: "ITC Officina Sans Std Book";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/OfficinaSansStd-Book.otf") format("opentype")
}

@font-face {
    font-family: "ITC Officina Sans Std Book";
    font-style: normal;
    font-weight: bold;
	src: url("/fonts-scorm/OfficinaSansStd-Bold.otf") format("opentype")
}

@font-face {
    font-family: "ITC Officina Sans Std Book";
    font-style: italic;
    font-weight: normal;
	src: url("/fonts-scorm/OfficinaSansStd-BookItalic.otf") format("opentype")
}

@font-face {
    font-family: "ITC Officina Sans Std Book";
    font-style: italic;
    font-weight: bold;
	src: url("/fonts-scorm/OFFICINASANSSTD-BOLDITALIC.OTF") format("opentype")
}

@font-face {
    font-family: "ITC Officina Serif Std Book";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/OfficinaSerifStd-Book.otf") format("opentype")
}

@font-face {
    font-family: "ITC Officina Serif Std Book";
    font-style: italic;
    font-weight: normal;
	src: url("/fonts-scorm/OfficinaSerifStd-BookItalic.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.0";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/srasans1.0-book.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.0";
    font-style: normal;
    font-weight: bold;
	src: url("/fonts-scorm/srasans1.0-bold.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.0";
    font-style: italic;
    font-weight: normal;
	src: url("/fonts-scorm/srasans1.0-italic.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.0";
    font-style: italic;
    font-weight: bold;
	src: url("/fonts-scorm/srasans1.0-bolditalic.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.1";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/srasans1.1-book.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.1";
    font-style: normal;
    font-weight: bold;
	src: url("/fonts-scorm/srasans1.1-bold.otf") format("opentype")
}

@font-face {
    font-family: "SRA Sans 1.1";
    font-style: italic;
    font-weight: normal;
	src: url("/fonts-scorm/SRASerif1.1-Italic.otf") format("opentype")
}


@font-face {
    font-family: "MT Extra";
	src: url("/fonts-scorm/MT-Extra.ttf") format("truetype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: normal;
    font-weight: bold;
	src: url("/fonts-scorm/TimesLTStd-Bold.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std Phonetic";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/TimesLTStd-Phonetic.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: normal;
    font-weight: normal;
	src: url("/fonts-scorm/TimesLTStd-Roman.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: italic;
    font-weight: bold;
	src: url("/fonts-scorm/TimesLTStd-BoldItalic.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: normal;
    font-weight: bolder;
	src: url("/fonts-scorm/TimesLTStd-ExtraBold.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: italic;
    font-weight: normal;
	src: url("/fonts-scorm/TimesLTStd-Italic.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: normal;
    font-weight: 600;
	src: url("/fonts-scorm/TimesLTStd-SemiBold.otf") format("opentype")
}

@font-face {
    font-family: "Times LT Std";
    font-style: italic;
    font-weight: 600;
	src: url("/fonts-scorm/TimesLTStd-SemiBoldItalic.otf") format("opentype")
}
