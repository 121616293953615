//VARIABLES
$primary-color: #234e60;
$secondary-color: #179f93;
$dark-color: grey;
$middle-color: #ddd;
$light-color: white;
$warning-color: #f2ff7d;

body {
    background-color: $middle-color;
    overflow-x: hidden;
}

#tinymce {
    background: #fff;
}

header {
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 100px;
    margin-bottom: 30px;
    background-color: $primary-color;
    border-bottom: 2px solid $middle-color;

    h1 {
        color: $light-color;
    }
}

div.content {
    margin: 100px 100px 50px;
    padding: 10px;
    border-radius: 10px;
    background-color: $light-color;
    min-height: 800px;
}

.x-messages {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 82px;
    min-width: 100vw;
    z-index: 99999;

    column-gap: 5px;
    grid-gap: 5px;
    grid-column-gap: 5px;

    & div {
        min-width: calc(100% - 300px);
        max-width: calc(100% - 300px);
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-weight: bold;
        border-radius: 5px;

        & i.close {
            margin-left: 25px;
            color: #fff
        }
    }

    & div.x-errors {
        background-color: rgba(242, 162, 162, 0.85);
        color: #000;

        & i.close {
            color: rgb(207, 70, 70);
        }

    }

    & div.x-info {
        background-color: rgba(133, 192, 244, 0.85);
        color: #000;
        & i.close {
            color: rgb(63, 134, 196);
        }
    }

    & div.x-warning {
        background-color: rgba(242, 226, 162, 0.85);
        color: #000;
        & i.close {
            color: #e7690b;
        }
    }
}




//NAV
.nav-item {
    margin-left: 10px;
}

.tab-pane {
    padding-top: 10px;
}

a {
    color: $secondary-color;

    &:hover,
    &:focus,
    &:active {
        color: $primary-color !important;
    }
}

// LIST
div.book {
    margin-top: -2px;
}

ul {
    list-style: none;
    padding-left: 20px;
    margin-bottom: 0;
}

li,
div.book {
    padding: 10px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $dark-color;
    margin: -1px 0 0;

    > i {
        color: $secondary-color;
    }
}

// BUTTONS
.actions {
    float: right;

    .btn {
        padding: 3px 6px;
        margin: -3px 3px;
    }

    i {
        color: $light-color;
    }
}

.btn {
    border: none;

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.btn-primary {
    background-color: $secondary-color;

    &:hover,
    &:focus,
    &:active {
        background-color: $primary-color !important;
    }
}

// MODALS
iframe {
    border: none;
}

.modal-header {
    background-color: $primary-color;
    color: $light-color;

    button {
        color: $light-color;
    }
}

.modal-body select {
    margin-bottom: 1em;
}

.modal-title-input {
    display: flex;
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.modal-title-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.modal-title-input:focus-visible {
    outline-color: None;
}

//SORTABLE
#nav-fusion form {
    width: 100%;
}

.sortable {
    &:not(:first-child) {
        border-left: 2px solid $secondary-color;
    }

    .the-book-card {
        user-select: none;
        cursor: pointer;
        border: 1px solid;
        display:flex;
        justify-content: space-around;
        align-items: center;
    }

    span {
        float: right;
        font-style: italic;
        font-size: 12px;
    }
}

.bookname {
    width: 80%;
    display: flex;
    justify-content: space-around;
    gap: 5px;
}

// SELECT

.custom-select {
    display: inline-block;
    width: 100%;
    margin-bottom: 1em;
}

/*ADVANCED EDITION*/
.advEdition {
    display: none;
}

.screenAdvEd_style {
    padding: 8px;
    margin: 24px 8px;
    border: 2px solid #ff5100;
}

.pageAdvEd_style {
    border: 2px solid #3cff00;
    padding: 8px;
}

.titleAdvEd_style {
    padding: 8px;
    border: 2px solid #002fff;
}

.flex-grow {
    flex-grow: 1;
}

.advEditionInverse {
    display: none;
}

.coverpageInputs {
    input, label {
        width: 450px;
        text-align: center;
    }
}

._coverPagePreview {
    width: 100%;
    height: 66vh;
    max-height: 720px;
    max-width: 1024px;
    border: 2px solid gray;
    border-radius: 8px;
}

.warnings {
    > article, h3 {
        display: none;
    }
    li {
        border: unset;
        list-style-type: circle;
    }
    display: none;
    cursor: pointer;
    width: 400px;
    min-height: 50px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 100px;
    background: $warning-color;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border: 1px solid yellow;
    box-shadow: 4px 4px 5px 0px #9b9b9b;
}

.warnings.hidden {
    right: -340px;
}

.warn-badge {
    ::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        color: yellow;
        background: #ffff3f;
        border-radius: 100%;
        transform: translate(-5px, -12px);
    }
}

.wrap-spinner {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    /* z-index 9999 by other library */
    z-index: 9999;
    align-items: center;
    backdrop-filter: blur(5px);
}

.overflow-hidden {
    overflow: hidden;
}

.edit-item-title {
    display: none;
}

.edit-item-title input:focus {
    outline: none;
}

.select-header {
    display: flex;
    width: 100%;
    grid-gap: 0.5em;
}

.select-button.btn-secondary {
    background-color: #179f93;
    transition: background-color ease 0.3s;
}

.select-button.btn-secondary:hover:enabled {
    background-color: #234e60 
}

.select-header select {
    margin-right: 1em;
}

.select-header #activity-selector {
    flex: 1;
}